import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { filter, forEach, get, includes, join, last, map, pick, round, set, split, trim } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getImageKitLink, ROOT_NODE_API_URL } from 'constant';
import { isImageUrl } from 'utils/img_utils';
import { FxFilePdfOutlined, FxFileTwoTone, FxLoadingIcon, FxPaperClipOutlined } from '../sharedV2/FxIcons';
import { getPdfFileExtension } from 'utils/accountUtils/common';
import { COLOR_BLOCKED, COLOR_PRIMARY, COLOR_SUCCESS } from '../theme/colors';
import { getSep } from 'utils/realtime_utils';
export const ALLOWED_MIME_FILE_TYPES = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'text/csv',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/gif',
    'video/mp4',
    'video/x-msvideo',
    'video/quicktime',
    'application/vnd.google-earth.kml+xml',
    '.kml',
];
export var ALLOWED_MIME_FILE_TYPES_ENUM;
(function (ALLOWED_MIME_FILE_TYPES_ENUM) {
    ALLOWED_MIME_FILE_TYPES_ENUM["PDF"] = "application/pdf";
    ALLOWED_MIME_FILE_TYPES_ENUM["XLSX"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    ALLOWED_MIME_FILE_TYPES_ENUM["DOCX"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    ALLOWED_MIME_FILE_TYPES_ENUM["XLS"] = "application/vnd.ms-excel";
    ALLOWED_MIME_FILE_TYPES_ENUM["CSV"] = "text/csv";
    ALLOWED_MIME_FILE_TYPES_ENUM["JPEG"] = "image/jpeg";
    ALLOWED_MIME_FILE_TYPES_ENUM["PNG"] = "image/png";
    ALLOWED_MIME_FILE_TYPES_ENUM["JPG"] = "image/jpg";
    ALLOWED_MIME_FILE_TYPES_ENUM["GIF"] = "image/gif";
    ALLOWED_MIME_FILE_TYPES_ENUM["MP4"] = "video/mp4";
    ALLOWED_MIME_FILE_TYPES_ENUM["AVI"] = "video/x-msvideo";
    ALLOWED_MIME_FILE_TYPES_ENUM["MOV"] = "video/quicktime";
    ALLOWED_MIME_FILE_TYPES_ENUM["KML"] = "application/vnd.google-earth.kml+xml";
    ALLOWED_MIME_FILE_TYPES_ENUM["KML2"] = ".kml";
})(ALLOWED_MIME_FILE_TYPES_ENUM || (ALLOWED_MIME_FILE_TYPES_ENUM = {}));
export const ALLOWED_MIME_FILE_TYPES_EXTENSIONS = {
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'text/csv': ['.csv'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/gif': ['.gif'],
    'video/mp4': ['.mp4'],
    'video/x-msvideo': ['.avi'],
    'video/quicktime': ['.mov'],
    'application/vnd.google-earth.kml+xml': ['.kml'],
    '.kml': ['.kml'],
};
export function getValidUploadFileTypes(passedAcceptTypes = '') {
    if (!passedAcceptTypes) {
        return join(ALLOWED_MIME_FILE_TYPES);
    }
    const passedAcceptTypesArr = filter(map(split(passedAcceptTypes, ','), (v) => trim(v)), (v2) => !!v2);
    const filteredAcceptTypes = filter(passedAcceptTypesArr, (type) => {
        return includes(ALLOWED_MIME_FILE_TYPES, type);
    });
    return join(filteredAcceptTypes);
}
const isPDFFileSafe = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = new Uint8Array(get(e, 'target.result'));
            const text = new TextDecoder().decode(content);
            console.log('text', text);
            // Look for suspicious patterns
            const patterns = [/\/JavaScript/i, /\/JS/i, /\/Launch/i, /<script>/i];
            const suspicious = patterns.some((pattern) => pattern.test(text));
            resolve(!suspicious); // returns false if suspicious
        };
        reader.onerror = (e) => {
            console.log('Failed to read PDF', e);
            resolve(false);
        };
        reader.readAsArrayBuffer(file);
    });
};
export async function validateFileBeforeUpload(file, options = {}) {
    const accountId = get(window.FLEETX_LOGGED_IN_USER, 'accountId', '');
    const allowDotInFileName = includes([13032, 936], accountId);
    const {} = options;
    let error = false;
    let errorMsg = '';
    try {
        if (!file) {
            error = true;
            errorMsg = window.t('pleaseUploadAValidFile');
        }
        const fileName = `${file.name}`;
        // get the extension
        const ext = `${get(fileName.match(/\.(.+)$/), '[1]', '')}`;
        // Reject if ext still contain a dot (which is implemented as part of the double extension prevention)
        if (!allowDotInFileName && ext.indexOf('.') !== -1) {
            error = true;
            errorMsg = window.t('filenameWithTwoExtensionsError');
        }
        // reject if uploaded file type is not valid
        const type = file.type;
        if (!includes(ALLOWED_MIME_FILE_TYPES, type) && !includes(ALLOWED_MIME_FILE_TYPES, `.${ext}`)) {
            error = true;
            errorMsg = window.t('fileTypeIsNotAllowedError');
        }
        const isSafe = type === ALLOWED_MIME_FILE_TYPES_ENUM.PDF ? await isPDFFileSafe(file) : true;
        if (!isSafe) {
            error = true;
            errorMsg = window.t('suspiciousFile');
        }
    }
    catch (e) {
        error = true;
        errorMsg = window.t('pleaseUploadAValidFile');
        console.log(e);
    }
    return {
        error,
        errorMsg,
    };
}
export function getFileNameFromUrl(url) {
    const urlTemp = `${url}`;
    return `${urlTemp}`.substring(`${urlTemp}`.lastIndexOf('/') + 1);
}
export function getFileExtFromUrl(url) {
    return last(split(url, '.'));
}
export function getUrlToUploadFileObject(url, extra = {}) {
    return {
        ...extra,
        uid: uuidv4(),
        url: getImageKitLink(url),
        name: getFileNameFromUrl(url),
        status: 'done',
    };
}
export var FILE_UPLOAD_STATUS;
(function (FILE_UPLOAD_STATUS) {
    FILE_UPLOAD_STATUS["ERROR"] = "error";
    FILE_UPLOAD_STATUS["SUCCESS"] = "success";
    FILE_UPLOAD_STATUS["DONE"] = "done";
    FILE_UPLOAD_STATUS["UPLOADING"] = "uploading";
    FILE_UPLOAD_STATUS["REMOVED"] = "removed";
})(FILE_UPLOAD_STATUS || (FILE_UPLOAD_STATUS = {}));
export const UPLOAD_LIST_TYPE = {
    TEXT: 'text',
    PICTURE: 'picture',
    PICTURE_CARD: 'picture-card',
};
export function setFieldValueOnFile(file, pairs) {
    if (file) {
        forEach(pairs, (keyVale) => {
            const key = keyVale[0];
            let value = keyVale[1];
            if (keyVale[0] === 'error') {
                value = new Error(value);
            }
            set(file, key, value);
        });
    }
    return file;
}
export const iconRender = (onClick) => {
    const onClickHandler = (event, file) => {
        event.stopPropagation();
        event.preventDefault();
        onClick(file);
    };
    return (file, uploadListType) => {
        if (file.status === FILE_UPLOAD_STATUS.UPLOADING) {
            return _jsx(FxLoadingIcon, {});
        }
        if (uploadListType === UPLOAD_LIST_TYPE.TEXT) {
            return _jsx(FxPaperClipOutlined, {});
        }
        if (includes([UPLOAD_LIST_TYPE.PICTURE_CARD, UPLOAD_LIST_TYPE.PICTURE], uploadListType)) {
            const url = get(file, 'thumbUrl', get(file, 'url'));
            const isPdf = getPdfFileExtension(url);
            if (isImageUrl(url)) {
                return (_jsx("img", { width: 48, height: 48, onClick: (event) => onClickHandler(event, file), src: url, alt: 'img' }));
            }
            else if (isPdf) {
                return _jsx(FxFilePdfOutlined, {});
            }
            else {
                return _jsx(FxFileTwoTone, {});
            }
        }
    };
};
export function getMimeTypeToExtension(mimeType) {
    const ext = get(split(mimeType, '/'), '[0]');
    if (ext && includes(ALLOWED_MIME_FILE_TYPES_EXTENSIONS[mimeType], ext)) {
        return ext;
    }
    else {
        return ALLOWED_MIME_FILE_TYPES_EXTENSIONS[mimeType]?.[0];
    }
}
export function customProgressBarConfig() {
    return {
        strokeColor: {
            '0%': COLOR_PRIMARY,
            '100%': COLOR_SUCCESS,
        },
        strokeWidth: 2,
        format: (percent) => percent && _jsx("span", { className: 'mr-1', children: `${parseFloat(percent.toFixed(2))}%` }),
    };
}
export function getShowUploadListConfig(propsToMerge = {}) {
    const { showOnlyButton, ...rest } = propsToMerge;
    if (showOnlyButton) {
        return false;
    }
    return {
        extra: ({ size = 0, imageTag }) => {
            return (_jsxs("span", { children: [!!imageTag && (_jsxs("span", { style: {
                            color: COLOR_BLOCKED,
                        }, children: [getSep(), imageTag] })), !!size && (_jsxs("span", { style: {
                            color: COLOR_BLOCKED,
                        }, children: [getSep(), round(size / 1024 / 1024, 2), "MB"] }))] }));
        },
        ...rest,
    };
}
export function getParsedUploadedDocumentList(fileList, keyForUrl = 'url', keyForImageTag = 'imageTag', extraKeysToPick = []) {
    return filter(map(fileList, (file) => {
        return {
            [keyForUrl]: get(file, keyForUrl, get(file, 'url', '')),
            [keyForImageTag]: get(file, keyForImageTag, get(file, 'imageTag', '')),
            ...pick(file, ['id']),
            ...pick(file, extraKeysToPick),
        };
    }), (file) => get(file, 'id') || get(file, 'url', get(file, keyForUrl, '')));
}
export function getImageKitUploadEndpoint(localTransporterAccount = null) {
    const useNappEndpoint = includes([11027, 14008], localTransporterAccount || get(window.FLEETX_LOGGED_IN_USER, 'accountId', '')) || true;
    if (useNappEndpoint) {
        return `${ROOT_NODE_API_URL}napp/misc/imagekit/upload`;
    }
    else {
        return `https://upload.imagekit.io/api/v1/files/upload`;
    }
}
