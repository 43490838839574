import { createGlobalStyle } from './utils';
import { COLOR_BG_CONTAINER_DARK, COLOR_BG_CONTAINER_DISABLED, COLOR_BORDER_DARK, COLOR_BORDER_SECONDARY, COLOR_ERROR, COLOR_HOVER_DARK, COLOR_PRIMARY, COLOR_PRIMARY_HOVER, COLOR_SUCCESS, COLOR_TEXT_DARK, COLOR_WARNING, } from './colors';
const DefaultCssBaseline = createGlobalStyle `
    html {
        body {

        }
    }
`;
const darkThemeCssBaseLine = createGlobalStyle `
    html {
           [ class *= "fxicon-" ]: before {
              color: rgba(255, 255, 255, 0.85) !important;
           }
        /* --------Dark theme styles for react-bootstrap-daterangepicker--------- */

        .daterangepicker {
            background-color: ${COLOR_BG_CONTAINER_DARK};
            color: ${COLOR_TEXT_DARK};
            border: 1px solid ${COLOR_BORDER_DARK};

            &.opensleft::after {
                right: 8px
            }

            &::after {
                top: -8px;
                border-width: 8px !important;
                border-bottom-color: ${COLOR_BORDER_SECONDARY};
            }

            .calendar-table {
                background-color: ${COLOR_BG_CONTAINER_DARK};
                border: none;

                .prev, .next {
                    span {
                        border-color: ${COLOR_TEXT_DARK};
                    }

                    &:hover {
                        background-color: ${COLOR_HOVER_DARK};
                    }
                }
            }


            select {
                border-color: ${COLOR_BORDER_DARK} !important;;
                background-color: ${COLOR_BG_CONTAINER_DARK} !important;;
                color: ${COLOR_TEXT_DARK} !important;
            }


            td.available:hover,
            td.active,
            td.active:hover {
                background-color: ${COLOR_HOVER_DARK};
            }

            td.start-date.available.active, td.end-date.available.active {
                background-color: ${COLOR_PRIMARY};
            }


            td.ends,
            td.disabled {
                background-color: ${COLOR_BG_CONTAINER_DISABLED} !important;
            }

            td.in-range:not(.off) {
                background-color: ${COLOR_BORDER_SECONDARY};
            }

            .ranges {
                li {
                    border: none;
                    background-color: ${COLOR_BORDER_SECONDARY};
                    color: ${COLOR_TEXT_DARK};

                    &:hover, &.active {
                        border: none;
                    }
                }
            }

            .drp-buttons {
                background-color: ${COLOR_BG_CONTAINER_DARK};
                border-top: 1px solid ${COLOR_BORDER_DARK};

                .btn-default, .calendar-cancel-custom-class {
                    color: ${COLOR_TEXT_DARK};
                    background-color: ${COLOR_BG_CONTAINER_DARK};
                    border-color: ${COLOR_BORDER_DARK};

                    &:hover {
                        background-color: ${COLOR_HOVER_DARK};
                        color: ${COLOR_PRIMARY};
                        border-color: ${COLOR_PRIMARY};
                    }
                }

                .btn-primary {
                    background-color: ${COLOR_PRIMARY};
                    border-color: ${COLOR_PRIMARY};

                    &:hover {
                        background-color: ${COLOR_PRIMARY_HOVER};
                        border-color: ${COLOR_PRIMARY_HOVER};
                    }
                }
            }
        }

        /* --------Dark theme styles for leaftlet map--------- */

        .map-theme-enabled {
            .leaflet-layer,
            .leaflet-control-zoom-in,
            .leaflet-control-zoom-out,
            .leaflet-control-attribution {
                filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
            }
        }
        .custom-background {
            background: ${COLOR_BG_CONTAINER_DARK};
        }

        /* --------Dark theme styles for rich text editor--------- */
        .rdw-editor-wrapper {
            background-color: ${COLOR_BG_CONTAINER_DARK} !important;
            border-color: ${COLOR_BORDER_DARK} !important;
            color: ${COLOR_TEXT_DARK} !important;
            &:hover {
                border-color: ${COLOR_PRIMARY} !important;
            }
        }
        .rdw-editor-toolbar {
            background-color: ${COLOR_BG_CONTAINER_DARK} !important;
            border-color: ${COLOR_BORDER_DARK} !important;
            color: ${COLOR_TEXT_DARK} !important;
            .rdw-option-wrapper {
                background-color: ${COLOR_BG_CONTAINER_DARK} !important;
                border-color: ${COLOR_BORDER_DARK} !important;
                color: ${COLOR_TEXT_DARK} !important;
                img {
                    filter: invert(1) brightness(.9);
                }
            }
            .rdw-dropdown-wrapper {
                background-color: ${COLOR_BG_CONTAINER_DARK} !important;
                border-color: ${COLOR_BORDER_DARK} !important;
                color: ${COLOR_TEXT_DARK} !important;
            }
            .rdw-dropdown-carettoopen {
                border-top-color: ${COLOR_TEXT_DARK} !important;
            }
            .rdw-dropdown-carettoclose {
                border-bottom-color: ${COLOR_TEXT_DARK} !important;
            }

        }

        .rdw-dropdown-optionwrapper {
            background-color: ${COLOR_BG_CONTAINER_DARK} !important;
            border-color: ${COLOR_BORDER_DARK} !important;
            color: ${COLOR_TEXT_DARK} !important;

            .rdw-dropdownoption-active,
            .rdw-dropdownoption-highlighted {
                background-color: ${COLOR_HOVER_DARK} !important;
            }
        }
        .img-icon-on-dark-theme {
            filter: invert(1) brightness(1.4);
        }
    }
`;
const DEFAULT_THEME = {
    token: {
        zIndexPopupBase: 1050,
        colorPrimary: COLOR_PRIMARY,
        colorLink: COLOR_PRIMARY,
        colorLinkHover: COLOR_PRIMARY_HOVER,
        colorWarning: COLOR_WARNING,
        colorError: COLOR_ERROR,
        colorSuccess: COLOR_SUCCESS,
        borderRadius: 4,
        borderRadiusLG: 4,
        fontFamily: "Inter,-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    },
    components: {
        Layout: {
            bodyBg: '#fff',
            siderBg: '#fff',
        },
        Image: {
            colorBgMask: 'rgba(0, 0, 0, 0.8)',
        },
        Dropdown: {
            zIndexPopupBase: 1021,
        },
        Modal: {
            zIndexPopupBase: 1020,
        },
        Alert: {
            colorSuccessBg: '#f6ffed',
            colorSuccessBorder: '#b7eb8f',
        },
    },
};
export default {
    getCssBaseline: (options) => {
        if (options?.currentThemeMode === 'dark') {
            return darkThemeCssBaseLine;
        }
        return DefaultCssBaseline;
    },
    theme: DEFAULT_THEME,
};
